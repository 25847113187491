<template>
    <!--选择授权人-->
    <section class="content-box">
        <div class="search-box">
            <i-header title="选择授权人" :showBack="true" @back-event="$router.go(-1)">
            </i-header>

            <i-search v-model="keyword"
                      shape="round"
                      clearable
                      clear-trigger="always"
                      @input="onSearch" placeholder="请输入姓名/拼音/邮箱/员工号"></i-search>
        </div>
        <i-list v-if="dataList.length" class="cnt-box" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div class="item"
                 @click="confirmItem(item)"
                 v-for="(item,index) in dataList" :key="index">
                <div class="left">
                    <i-icon :color="item.isChecked ? 'var(--themeColor)' : '#DEDEDE'" name="icon-checkbox2" :size="18"></i-icon>
                </div>
                <div class="right">
                    <div class="title">{{ item.name }} <div class="sub_title">{{item.extId}}</div></div>
                    <div class="desc" style="margin-top: 0.08rem"> <i-icon size="14" color="#13161B" name="icon-qita" />{{ item.group6 || '-' }}</div>
                    <div class="desc"><i-icon size="14" color="#13161B" name="icon-youxiang"/>{{ item.email || '（暂无）' }}</div>
                </div>
            </div>
        </i-list>
        <empty-box v-if="!dataList.length" />
        <div class="footer">
            <div class="btn" @click="onSubmit()">确定</div>
        </div>
    </section>
</template>

<script>
// import removeEmployee from './removeEmployee.js';
import emptyBox from "@/components/iEmpty";
export default {
    // extends: removeEmployee,
    components: {emptyBox},
    data() {
        return {
            loading: false,
            finished: true,
            isShowPopup: false,
            isFirst: true,
            keyword: '',
            pageIndex: 1,
            pageSize: 50,
            dataList: [],
            total: 0,
            loadType: "",
            miceId: ''
        }
    },
    watch: {
        keyword() {
            this.$nextTick(() => {
                this.onSearch();
            }, 200);
        }
    },
    created() {

    },
    mounted() {
        this.miceId = this.$route.query.miceId;
        this.loadData(true);
    },
    methods: {
        onLoad() {
            if (this.pageSize * this.pageIndex >= this.total) {
                this.finished = true;
            } else {
                this.pageIndex += 1;
                this.getList();
            }
        },
        showPopup() {
            this.isShowPopup = true;
            this.loadData(true);
        },
        // 搜索
        onSearch() {
            this.loadData(true);
        },
        loadData(clear) {
            let params = {
                agentType: "2",
                marker: this.keyword,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                noCount: false,
                miceId: this.miceId
            }
            this.$service.getAppliedList(params).then(res => {
                this.isFirst = false;
                if (res.success) {
                    if (clear) {
                        this.dataList = [];
                    }
                    this.dataList = this.dataList.concat(res.content.rows);
                    this.total = res.content.total;
                    this.loadType = "";
                    //不能选自己
                    this.dataList = this.dataList.filter(ele => {
                        return ele.userId != this.$cookie.get('userId');
                    })
                }
            })
        },
        confirmItem(item) {
            this.$nextTick(() => {
                if (!item.isChecked) {
                    this.SaveEmployee(item);
                } else {
                    this.removeEmployee({
                        onSuccess: () => {
                            item.isChecked = false;
                        },
                        item: item
                    });
                }
            }, 200)
        },
        SaveEmployee(item) {
            let params = {
                agentType: '2',
                miceId: this.miceId,
                employeeId: item.employeeId,
                userId: item.userId
            }
            this.$service.selectedAuthorizer(params).then(res => {
                if (res.success) {
                    item.isChecked = true;
                    item.agentId = res.content.agentId;
                }
            });
        },
        //保存
        onSubmit() {
            this.AuthorizerEditorGoBack();
        },
        //返回
        AuthorizerEditorGoBack() {
            // this.isShowPopup = false;
            // this.$parent.loadData(); //返回刷新
            this.$router.back()
        },
        scrollTop(filter) {
            this.loadType = "top";
            this.pageIndex = 1;
            if (filter) {
                this.dataList = [];
            }
            this.loadData(true);
        },
        scrollBottom() {
            if (this.pageSize * this.pageIndex >= this.total) {
                this.loadType = "none";
            } else {
                this.loadType = "bottom";
                this.pageIndex += 1;
                this.loadData();
            }
        },
        removeEmployee({onSuccess, item}) {
            let params = {
                agentType: '2',
                agentId: item.agentId
            }
            this.$service.removeAuthorizer(params).then(res => {
                if (res.success) {
                    onSuccess(res)
                }
            });
        }
    }
}

</script>

<style lang="less" scoped>
.search-box {
    //padding: 0rem 0.18rem;
    box-sizing: border-box;

    svg {
        //margin-left: 0.1rem;
    }
    .back-icon {
        height: 0.3rem;
        display: flex;
        align-items: center;
        margin-right: 0.1rem;
        flex-shrink: 0
    }

    /deep/ .van-search {
        //width: 100%;
        padding: unset;
        margin: 0 0.12rem;
        background: #F5F5F5;
        border-radius: 0.04rem;

        .van-search__content {
            height: 0.3rem;
            background: #F5F5F5;
            //box-shadow: 0px 0.02rem 0.13rem 0px rgba(35, 24, 21, 0.1);
            display: flex;
            align-items: center;
            font-size: 0.14rem;

            .van-cell {
                height: 0.3rem;
                box-sizing: border-box;
                display: flex;
                align-items: center;
            }
            .van-field__control {
                height: 0.3rem;
            }
            .van-field__control::placeholder {
                color: #909399;
                -webkit-text-fill-color: #909399 !important;
            }
        }
    }

    /deep/ .van-icon-search {
        color: #c0c4cc;
    }
}

.cnt-box{
    height: calc(100vh - 1.54rem);
    overflow: auto;
}

.item {
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
    .left {
        .icon-radio2{
            color: var(--themeColor);
        }
    }
    .right {
        width: 100%;
        box-sizing: border-box;
        padding: 0.14rem 0;
        border-bottom: 1px solid #F5F5F5;
        margin-left: 0.16rem;
        .title{
                font-size: 0.16rem;
                font-weight: bold;
                color: #13161B;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                .sub_title{
                    font-size: 0.12rem;
                    font-weight: lighter;
                    color: #909399;
                    margin-left: 0.1rem;
                }
            }
        .desc {
            font-size: 0.14rem;
            color: #13161B;
            font-weight: normal;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            svg{
                margin-right: 0.06rem;
            }
        }
        .textEllipsis {
            max-width: 70vw;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
.footer {
    width: 100%;
    //height: 0.44rem;
    padding: 0.18rem 0.1rem;
    box-shadow: 0px 0.04rem 0.04rem 0px #FFFFFF;
    //padding: 0 0.25rem;
    box-sizing: border-box;
    .btn {
        height: 0.44rem;
        background: var(--themeColor);
        //box-shadow: 0px 0.44vw 2.22vw 0px rgba(13, 49, 129, 0.2);
        border-radius: 0.04rem;
        display: flex;
        align-items: center;
        justify-content: center;
        //font-size: 0.16rem;
        //font-weight: 500;
        color: #FFFFFF;
        //margin-top: 1.4vw;
    }
}
</style>
